var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: `EPOCH_FORM_${_vm.id}` },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("epoch.date"), "label-for": "epoch" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-datepicker", {
                    staticClass: "mb-2",
                    attrs: {
                      id: "activity-startDate",
                      "today-button": "",
                      "reset-button": "",
                      "close-button": "",
                      "hide-header": "",
                      boundary: "viewport",
                      min: _vm.minDate,
                      max: _vm.maxDate,
                      "popper-opts": { positionFixed: true },
                      "label-today-button": _vm.$t("date.today"),
                      "label-reset-button": _vm.$t("date.reset"),
                      "label-close-button": _vm.$t("date.close"),
                      "today-button-variant": "primary",
                      "reset-button-variant": "danger",
                      "close-button-variant": "secondary",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function ({}) {
                          return [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "calendar-days"] },
                            }),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.epoch,
                      callback: function ($$v) {
                        _vm.epoch = $$v
                      },
                      expression: "epoch",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }